
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'RecommendationAdPill',
})
export default class RecommendationAdPill extends Vue {
	@Prop({ default: false }) topPosition: boolean;

	clickHandler() {}
}
