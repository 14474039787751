import ListsForSignedInUsersModal from '@/components/modals/ListsForSignedInUsersModal.vue';

import { ModalHelper } from '@/helpers/modal-helper';
import { getVm } from '@/helpers/vm-helper';
import { isProOnboardingExperimentEnabled } from '@/helpers/pro-web-helper';
import { useLanguageStore, useUserStore } from '@/helpers/composables/useStores';

import { AccountModal } from '@/lazy-load-components';
import { AccountModes } from '@/enums/login';

const { webLocale } = useLanguageStore();
const { isPremium } = useUserStore();

const hasProOnboardingEnabled = !isPremium.value && isProOnboardingExperimentEnabled(webLocale.value);

export const preventNonSignedInAction = () => {
	const vm = getVm();
	if (!vm.$store.getters['user/isLoggedIn']()) {
		if (hasProOnboardingEnabled) {
			ModalHelper.openModal(
				AccountModal,
				{ closable: true, startScreen: AccountModes.REGISTRATION_OPTIONS },
				{ cssClass: 'account-modal' },
				'FreemiumSignUpModal'
			);

			return true;
		}

		ModalHelper.openModal(
			ListsForSignedInUsersModal,
			{
				closable: true,
				header: vm.$t('WEBAPP_YOUNOWNEEDANACCOUNTFORLISTSHEADER'),
				body: vm.$t('WEBAPP_YOUNOWNEEDANACCOUNTFORLISTSBODY'),
			},
			{ cssClass: 'lists-for-signed-in-users-modal' }
		);

		return true;
	}

	return false;
};
