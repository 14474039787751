
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { ObjectType, SraEventAction, TitleListType } from '@/@types/graphql-types';

import ImpressionTrackingMixin from '@/mixins/ImpressionTrackingMixin.vue';

import HiddenHorizontalScrollbar from '@/components/HiddenHorizontalScrollbar.vue';
import PosterGraphql from '@/components/poster/PosterGraphql.vue';
import TitleDetailLink from '@/components/nav/links/TitleDetailLink.vue';

import { AvailabilityBadgeSize } from '@/components/poster/types/availability-badge';

import { ImpressionTrackingEvents } from '@/enums/events';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import type { Offer } from '@/@types/graphql-types';
import { mrcObservableOptions } from '@/helpers/composables/useBatchImpressionTracking';
import type { CollectionType } from '@/enums/collection-type';
import type { WebLocale } from '@/enums/web-locale';
import type { SnowplowContext, SnowplowModuleContext } from '@/helpers/tracking/providers/snowplow-contexts';
import type { PosterGraphqlProps } from '@/interfaces/components/poster/PosterGraphql';
import type { FormattedNewTitle } from '@/interfaces/new-title-graphql';
import { NewOffer } from '@/interfaces/offer-graphql';
import type { TitleDetail } from '@/interfaces/title-details-graphql';
import BackendTrackingMixin from '@/mixins/BackendTracking.vue';
import type { PopularTitleGraphqlFragment } from '@/pages/graphql/fragments/PopularTitle.fragment';
import { WatchNowOfferFragment } from '@/pages/graphql/fragments/WatchNowOffer.fragment';
import type { WatchlistTitleGraphqlFragment } from '@/components/watchlist/graphql/fragments/WatchlistTitle.fragment';
import type { FilterCollection } from '@/stores/filter.store';

const TitleCardPricechange = () =>
	import(/* webpackChunkName: "title-card-pricechange" */ '@/components/list/TitleCardPricechange.vue');

const Filter = namespace('filter');
const Language = namespace('language');
const User = namespace('user');

export type HorizontalTitleListItem = PopularTitleGraphqlFragment &
	WatchlistTitleGraphqlFragment['node'] &
	TitleDetail &
	FormattedNewTitle; // TODO: get rid of this handcrafted type;

@Component<HorizontalTitleListGraphql>({
	name: 'HorizontalTitleListGraphql',
	components: {
		PosterGraphql,
		HiddenHorizontalScrollbar,
		TitleCardPricechange,
		TitleDetailLink,
	},
})
export default class HorizontalTitleListGraphql extends Mixins(ImpressionTrackingMixin, BackendTrackingMixin) {
	@Prop({ type: String }) clickSource: string;
	// change this to module
	@Prop({ default: () => [] }) titles: HorizontalTitleListItem[];
	@Prop({ default: null }) sponsoredAd: SponsoredAdFragment;
	// the horizontal infinite scroll will stop fetching after this amount has been reached.
	@Prop({ default: 0 }) maxTitles: number;
	@Prop({ default: false }) showRibbon: boolean;
	@Prop({ default: false }) showCheck: boolean;
	@Prop({ default: false }) showQuickActions: boolean;
	@Prop({ default: false }) forceQuickActions: boolean;
	@Prop({ default: true }) hasWatchNow: boolean;
	@Prop({ default: false }) showPriceDrop: boolean;
	@Prop({ default: false }) showProviderIcon: boolean;
	@Prop({ default: false }) showTitle: boolean;
	@Prop({ default: true }) showTvBadge: boolean;
	// whether data is still fetching and blocking elements need to be displayed
	@Prop({ default: false }) isBlocking: boolean;
	@Prop({ default: false }) smallPosters: boolean; // from width 166px to 128px
	@Prop({ default: false }) preventDefault: boolean;
	@Prop({ default: false }) showSeenList: boolean; // whether to show ignore and seen list in poster
	@Prop({ default: false }) resetScrollOnChange: boolean;
	@Prop({ default: false }) hasMoreLikeThis: boolean;
	@Prop({ default: () => [] }) declare additionalContexts: (SnowplowModuleContext | SnowplowContext)[]; // additional contexts that will be passed down to actions like watchlist_add
	@Prop({ default: 0 }) imgsLoadCount: number;
	@Prop({ default: false }) emitOnScroll: boolean;
	@Prop({ default: false }) showPosition: boolean;
	@Prop({ default: 'primary' }) showPositionColor: 'primary' | 'brand';
	@Prop({ default: false }) fadedEdge: boolean;
	@Prop({ default: null }) watchNowOffer: WatchNowOfferFragment;

	@Ref('horizontal-scrollbar') horizontalScrollbar?: HiddenHorizontalScrollbar;

	@Filter.Getter filters: (collectionType: CollectionType) => FilterCollection;

	@Language.Getter declare language: string;
	@Language.Getter declare country: string;
	@Language.Getter locale: string;
	@Language.State webLocale: WebLocale;

	@User.Getter isLoggedIn: (providerName?: string) => boolean;
	@User.Getter isPremium: boolean;

	isMounted = false;
	private isReady = true;
	TitleListType = TitleListType;

	get isRibbonVisible() {
		if (this.showRibbon) {
			return this.showRibbon;
		}

		// make sure we can disable the ribbon specifically
		if (!this.showRibbon && !this.showQuickActions) {
			return false;
		}

		return !this.shouldShowQuickActions();
	}

	get titlesToDisplay() {
		return this.titles;
	}

	get styleScrollBar() {
		return { minHeight: `${this.smallPosters ? 180 : 236}px` };
	}

	get sponsoredRecommendationPackageId() {
		return this.sponsoredAd?.campaign.watchNowOffer.package.packageId;
	}

	get sponsoredRecommendationCohort() {
		return this.sponsoredAd?.holdoutGroup ? '0' : '1';
	}

	get sponsoredRecommendationType() {
		return (this.sponsoredAd?.campaign?.node as any)?.type;
	}

	get sponsoredRecommendationTitleList() {
		return this.sponsoredAd?.campaign?.node.nodeId;
	}

	getPositionColor(position: number) {
		if (this.showPositionColor === 'primary') {
			return '#222c38';
		}

		let numberColor = '#222c38';

		switch (position) {
			case 1:
				numberColor = '#ffd700';
				break;
			case 2:
				numberColor = '#c0c0c0';
				break;
			case 3:
				numberColor = '#cd7f32';
				break;
		}

		return numberColor;
	}

	someOffer(title: HorizontalTitleListItem) {
		return title.watchNowOffer ?? title.newOffer ?? title.offers?.[0];
	}

	handleClickOut({ title, position }: { title: any; position: number }) {
		this.$emit('onPosterClickOut', {
			index: position,
			title,
			titleList: this.sponsoredRecommendationTitleList,
			event: SraEventAction.Clickout,
		});
	}

	handlePosterClick(index: number, title: any, titleList?: any) {
		this.$emit('onPosterClick', { index, title, titleList });
		if (this.sponsoredAd?.campaign?.node.nodeId === title.id) {
			const label =
				(this.sponsoredAd?.campaign?.node as any)?.type === 'TECHNICAL_USER_LIST' && titleList
					? `${titleList}_${this.sponsoredRecommendationPackageId}`
					: `${title.id}_${this.sponsoredRecommendationPackageId}`;
			TrackingHelper.trackEvent(
				ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS,
				{
					action: 'click',
					label,
					property: 'poster',
				},
				// this.additionalContexts
				[]
			);
		}
	}

	onVisibleCallback(id: string, isVisible?: boolean, entry?: IntersectionObserverEntry) {
		if (isVisible) {
			this.onVisible(id, isVisible, entry);
		}
	}

	_impressionCallback(title: HorizontalTitleListItem) {
		if (this.sponsoredAd?.campaign?.node.nodeId === (title?.show.id || title.id)) {
			return this.$backendTracking.trackSraEvent(SraEventAction.Impression, this.sponsoredAd);
		}
		return;
	}

	getPosterGraphqlEvents(title: HorizontalTitleListItem, index: number) {
		return {
			impressionCallback: () => this._impressionCallback(title),
			moreInfosClicked: () => this.navigateTitleGraphql(title),
			openModal: () => this.$emit('openModal', { index, title }),
			hovered: () => this.$emit('hovered', { index, title }),
			click: () => this.handlePosterClick(index, title, this.sponsoredRecommendationTitleList),
			onVisibleCallback: this.onVisibleCallback,
		};
	}

	getPosterGraphqlProps(title: HorizontalTitleListItem, index: number): PosterGraphqlProps {
		const titleId = this.getTitleId(title);
		let impressionTrackingOptions = {};
		if (this.sponsoredAd?.campaign?.node.nodeId === titleId) {
			impressionTrackingOptions = {
				impressionTrackingActive: true,
				impressionTrackingCategory: ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS,
				impressionTrackingLabel: `${titleId}_${this.sponsoredRecommendationPackageId}`,
				impressionTrackingProperty: 'poster',
				impressionTrackingValue: this.sponsoredRecommendationCohort,
				impressionTrackingObservableOptions: mrcObservableOptions,
			};
		}

		return {
			titleId: titleId,
			titleObjectId: this.getTitleObjectId(title),
			titleObjectType: this.getTitleObjectType(title),
			titleName: this.getTitleName(title),
			titlePosterUrl: title.content.posterUrl ?? '',
			titleBackdrops: title.content.backdrops,
			titleScoring: title.content.scoring,
			titleSeasonNumber: this.getSeasonNumber(title),
			titleAvailableTo: title.availableTo,
			titleUpcomingReleases: title.content.upcomingReleases,
			seenEpisodeCount: this.getSeenEpisodeCount(title),
			watchNowOffer: this.getWatchNowOffer(title),
			isInWatchlist: this.isTitleInList(TitleListType.WatchNext, title),
			isTvShowTracked: this.isTvShowTracked(title),
			isInCustomList: this.isTitleInCustomList(title),
			isInSeenList: this.isTitleInList(TitleListType.CaughtUp, title),
			isInLikeList: this.isTitleInList(TitleListType.Likelist, title),
			isInDislikeList: this.isTitleInList(TitleListType.Dislikelist, title),
			isTitleReleased: title.content.isReleased,
			showRibbon: this.isRibbonVisible,
			showQuickActions: this.showQuickActions && this.shouldShowQuickActions() && this.isMounted,
			forceQuickActions: this.forceQuickActions,
			providerIds: this.showProviderIcon ? this.providerIds(title) : undefined,
			hasMoreLikeThis: this.hasMoreLikeThis,
			showWatchNowButton: this.hasWatchNow && !this.showPriceDrop,
			showTvBadge: this.showTvBadge,
			clickSource: this.clickSource,
			lazy: index >= this.imgsLoadCount,
			showAvailabilityBadge: true,
			availabilityBadgeSize: AvailabilityBadgeSize.Short,
			noRadius: this.showPriceDrop ? 'bottom' : undefined,
			additionalContexts: this.additionalContexts,
			smallPosters: this.sponsoredRecommendationType === 'TECHNICAL_USER_LIST',
			...impressionTrackingOptions,
		};
	}

	shouldShowQuickActions() {
		return this.isLoggedIn();
	}

	public mounted() {
		this.isMounted = true;
		this.$emit('mounted');
	}

	private complete() {
		this.isReady = true;
	}

	scrolledRight() {
		if (this.isReady && this.titlesToDisplay.length > 0 && this.titlesToDisplay.length < this.maxTitles) {
			this.isReady = false;
			this.$emit('onScrollRight', this.complete);
		}
	}

	/**
	 * use router to navigate to the title
	 */

	navigateTitleGraphql(title: HorizontalTitleListItem) {
		this.$router.push({ path: title.content.fullPath });
	}

	/**
	 * handle click event coming from Poster
	 *
	 * @param {Event} evt
	 * @param {Number} index
	 * @param {PopularTitle|NewTitle} title
	 *
	 * @event #clicked payload { index, title }
	 */
	clicked(evt: Event, index: number, title: HorizontalTitleListItem) {
		const { className = '', nodeName = '' } = evt.target as HTMLElement;
		const ignoreClassName: string[] = [];
		const ignoreNode = ['SVG', 'PATH'];

		// ignore click from font icons
		if (ignoreNode.indexOf(nodeName.toUpperCase()) !== -1) {
			evt.preventDefault();
			evt.stopPropagation();
			return;
		}

		// ignore click when from ignoreClassName elements
		if (ignoreClassName.indexOf(className) !== -1) {
			evt.preventDefault();
			evt.stopPropagation();
			return;
		}

		// emit click from img
		if (nodeName.toUpperCase() === 'IMG') {
			if (this.preventDefault) {
				// prevent img default behavior
				evt.preventDefault();
				evt.stopPropagation();
			}
			this.$emit('clicked', { index, title });
		}
	}

	/**
	 * Title providers ids, filtered by user's selected providers
	 * @param {Object<Title>} title Title
	 * @return {Array.<number>} Array of Title ids
	 */
	providerIds(title: any): number[] {
		const offers = title.offers as Offer[];
		if (offers) {
			const uniqueProviderOffers = this.uniqueProviderOffers(offers);
			return uniqueProviderOffers.map((offer: Offer) => offer.package.packageId);
		}

		return [];
	}

	/**
	 * Offers unique provider
	 * @param {Array.<Offer>} offers Array of offers
	 * @return {Array.<Offer>} Array of offers
	 */
	uniqueProviderOffers(offers: Offer[]): Offer[] {
		const providerIds = offers.map((offer: Offer) => offer.package.packageId);
		return offers.filter((offer: Offer, index) => providerIds.indexOf(offer.package.packageId) === index);
	}

	isTvShowTracked(title: HorizontalTitleListItem) {
		return this.isShowAvailableOnSeason(title)
			? // @ts-ignore
			  !!title?.show?.tvShowTrackingEntry?.createdAt
			: // @ts-ignore
			  !!title?.tvShowTrackingEntry?.createdAt;
	}
	isTitleInCustomList(title: HorizontalTitleListItem) {
		return this.isShowAvailableOnSeason(title)
			? // @ts-ignore
			  !!title?.show?.customlistEntries?.length
			: !!title?.customlistEntries?.length;
	}

	isTitleInList(listName: TitleListType, title: HorizontalTitleListItem) {
		switch (listName) {
			case TitleListType.WatchNext:
				return this.isShowAvailableOnSeason(title)
					? !!title?.show?.watchlistEntryV2?.createdAt
					: !!title?.watchlistEntryV2?.createdAt;
			case TitleListType.Likelist:
				return this.isShowAvailableOnSeason(title)
					? !!title?.show?.likelistEntry?.createdAt
					: !!title?.likelistEntry?.createdAt;
			case TitleListType.Dislikelist:
				return this.isShowAvailableOnSeason(title)
					? !!title?.show?.dislikelistEntry?.createdAt
					: !!title?.dislikelistEntry?.createdAt;
			case TitleListType.CaughtUp:
				return title.__typename !== 'Movie'
					? title?.seenState?.progress === 100
					: !!title?.seenlistEntry?.createdAt;
		}
	}

	isShowAvailableOnSeason(title: HorizontalTitleListItem) {
		return title.objectType === ObjectType.ShowSeason && title?.show;
	}

	getSeenEpisodeCount(title: HorizontalTitleListItem) {
		return title.__typename === 'Show' ? title.seenState?.seenEpisodeCount : undefined;
	}

	getTitleId(title: HorizontalTitleListItem) {
		if (title?.show?.id) {
			return title?.show?.id;
		}

		return title.id;
	}

	getTitleObjectId(title: HorizontalTitleListItem) {
		if (title?.show?.objectId) {
			return title?.show?.objectId;
		}

		return title.objectId;
	}

	getTitleObjectType(title: HorizontalTitleListItem) {
		if (title?.show?.objectType) {
			return title?.show?.objectType;
		}

		return title.objectType;
	}

	getTitleName(title: HorizontalTitleListItem) {
		if (title?.show?.content?.title) {
			return title?.show?.content?.title;
		}

		return title.content.title;
	}

	getSeasonNumber(title: HorizontalTitleListItem) {
		if (title.objectType === ObjectType.ShowSeason) {
			return title.content.seasonNumber;
		}

		return undefined;
	}

	getWatchNowOffer(title: HorizontalTitleListItem): Offer | NewOffer | undefined {
		return title.newOffer ?? title.watchNowOffer;
	}
}
