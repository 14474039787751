
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BackendTrackingMixin from '@/mixins/BackendTracking.vue';
import ImpressionTrackingMixin, { ObservableOptions } from '@/mixins/ImpressionTrackingMixin.vue';
import TitleWatchlistMutationMixin from '@/mixins/title-quick-action-mutations/TitleWatchlistMutationMixin.vue';

const MarkAsSeenModalGraphql = () => import('@/components/mark-as-seen/MarkAsSeenModalContainerGraphql.vue');
const TitleScoreList = () => import('@/components/TitleScoreList.vue');

import WatchNowButton from '@/components/buttons/WatchNowButton.vue';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import ImpressionPixel from '@/components/sponsored-recommendations/ImpressionPixel.vue';
import RecommendationAdPill from '@/components/sponsored-recommendations/RecommendationAdPill.vue';

import {
	SnowplowClickoutContextGraphql,
	SnowplowContext,
	SnowplowModuleContext,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers/snowplow-contexts';
import { hasCCPABanner, hasUCConsentBanner } from '@/helpers/tracking';

import { ExternalTracker, ExternalTrackerType, MonetizationType, SraEventAction } from '@/@types/graphql-types';
import { ModalHelper } from '@/helpers/modal-helper';
import type { MarkAsSeenModalProps } from '../mark-as-seen/MarkAsSeen';
import { MarkAsSeenModalOptions } from '../mark-as-seen/MarkAsSeen';

import { ImpressionTrackingEvents } from '@/enums/events';

import { ClickoutUrl } from '@/helpers/clickout-helper';
import {
	injectExternalHtmlTrackers,
	handleExternalClickoutTrackers,
	formatExternalTracker,
} from '@/helpers/impression-tracker-helper';

import type { ListMutationTitleDetailParam } from '@/helpers/providers/title-actions-provider';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import type { SRClickTitleEvent, SingleTitleSR } from '@/components/sponsored-recommendations/types';

import { ReadyType } from '@/stores/ready.store';
import { WebLocale } from '@/enums/web-locale';
import { TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getSrPlacementFromBidId } from './useSponsoredRec';

const Language = namespace('language');
const TitleDetailsStore = namespace('titleDetails');
const User = namespace('user');

@Component<SponsoredRecommendationPoster>({
	name: 'SponsoredRecommendationPoster',
	components: {
		ImpressionPixel,
		ProviderIcon,
		RecommendationAdPill,
		TitleScoreList,
		WatchNowButton,
	},
})
export default class SponsoredRecommendationPoster extends Mixins(
	BackendTrackingMixin,
	ImpressionTrackingMixin,
	TitleWatchlistMutationMixin
) {
	impressionPixelsInternal: Array<string> = [];
	impressionPixelsExternal: Array<string> = [];

	@Prop({ default: () => [] }) declare additionalContexts: (SnowplowModuleContext | SnowplowContext)[];
	@Prop({ required: true }) declare sponsoredAd: SponsoredAdFragment;
	@Prop({ default: true }) lazy: boolean;

	@Language.Getter declare language: string;
	@Language.State webLocale: WebLocale;

	@TitleDetailsStore.Action setSponsoredRecommendation: (sponsoredAd: SponsoredAdFragment) => void;

	@User.Getter strictlyAllConsentsGranted: boolean;

	@Watch('isLoggedIn', { immediate: true })
	onIsLoggedInChange() {
		const { id, details } = this.loginStateTitleDetails;
		if (this.isLoggedIn()) {
			this.mixin_onIsLoggedInChange(
				id,
				details,
				this.isInWatchlist,
				ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS
			);
		}
	}

	/** IMPRESSION TRACKING */
	get observable() {
		return this.getObservableOptions(this.sponsoredAd?.campaign?.node.nodeId);
	}
	/** IMPRESSION TRACKING */

	get loginStateTitleDetails() {
		return {
			id: this.campaignNode.nodeId,
			details: {
				title: this.campaignNode.content.title,
				objectType: this.titleObjectType,
				objectId: this.titleObjectId,
			},
		};
	}

	get isInWatchlist() {
		if (this.campaignNode?.__typename === 'Season') {
			return !!this.campaignNode?.show.watchlistEntryV2?.createdAt;
		}

		return !!this.campaignNode?.watchlistEntryV2?.createdAt;
	}

	get promotionalImageUrl() {
		return this.sponsoredAd.campaign.promotionalImageUrl as string;
	}

	get posterKey() {
		return this.promotionalImageUrl || this.titleObjectId;
	}

	get campaignNode() {
		return this.sponsoredAd.campaign!.node as SingleTitleSR;
	}

	get watchNowLabel() {
		return this.sponsoredAd.campaign.watchNowLabel;
	}

	get promotionalTitle() {
		if (this.sponsoredAd.campaign?.promotionalTitle) {
			return this.sponsoredAd.campaign?.promotionalTitle;
		}

		const campaignTitle = this.campaignNode.content.title;

		if (this.campaignNode?.__typename === 'Season') {
			return `${this.campaignNode.show.content.originalTitle} - ${campaignTitle}`;
		}

		return campaignTitle;
	}

	get getTitleSize(): 'regular' | 'medium' | 'small' {
		const titleLength = this.promotionalTitle?.length ?? 0;

		if (titleLength >= 22 && titleLength < 32) return 'medium';

		if (titleLength >= 32) return 'small';

		return 'regular';
	}

	get promotionalText() {
		return this.sponsoredAd.campaign?.promotionalText || this.campaignNode.content.originalReleaseYear;
	}

	get promotionalProviderLogo() {
		return this.sponsoredAd.campaign?.promotionalProviderLogo;
	}

	get impressionTagInternal() {
		return '';
	}

	get impressionTagExternal() {
		return '';
	}

	get providerId() {
		return this.sponsoredAd.campaign.watchNowOffer.package.packageId;
	}

	get seenEpisodeCount() {
		return 'seenState' in this.campaignNode ? this.campaignNode.seenState?.seenEpisodeCount : null;
	}

	get watchNowOffer() {
		return this.sponsoredAd.campaign.watchNowOffer;
	}

	get clickoutContext() {
		return SnowplowClickoutContextGraphql.fromProviderOffer(this.watchNowOffer);
	}

	// Hack: add `uct_sr` for The Supremes campaign
	// Campaign Video:  THE_SUPREMES_AT_EARLS_ALL-YOU-CAN-EAT_BURST1_US_SRA_2
	get hasUctSrParam() {
		const regex = new RegExp(/THE_SUPREMES_AT_EARLS_ALL-YOU-CAN-EAT_BURST\d_US_SRA(.+)?/);

		return regex.test(this.sponsoredAd.campaign?.name);
	}

	get watchNowUctLink() {
		return new ClickoutUrl(this.watchNowOffer)
			.set('uct_country', this.country)
			.set('uct_sr', this.hasUctSrParam ? 'true' : 'false')
			.set('uct_tryout', 1)
			.toString();
	}

	get isCinemaSR() {
		return this.sponsoredAd.campaign?.watchNowOffer.monetizationType === MonetizationType.Cinema;
	}

	// HACK: APPLE PLUS - display disclaimer text
	get hasDisclaimerText() {
		return (
			this.sponsoredAd.campaign?.name.toUpperCase().includes('APPLEPLUS') &&
			this.sponsoredAd.campaign?.promotionalText
		);
	}

	/**
	 * Impression Experiment
	 * We want to observe changing the definition of impression to when the ad is loaded.
	 * This includes firing External Impressions events.
	 * The previous impression will be known as viewable impressions.
	 * Test campaign: THE_INSTIGATORS_TEST_BURST1_NO_SRA ad
	 */
	get hasImpressionExperiment() {
		return this.sponsoredAd?.campaign?.name.toUpperCase().includes('THE_INSTIGATORS_TEST_BURST1_NO_SRA');
	}

	async mounted() {
		this.injectExternalImpressionHtml();
		this.sendAdRenderedEvent();
	}

	activated() {
		this.sendAdRenderedEvent();
	}

	// needed by ImpressionTrackingMixin
	// Impression Experiment
	async impressionCallback() {
		const impressionAction: SraEventAction = this.hasImpressionExperiment
			? SraEventAction.ViewableImpression
			: SraEventAction.Impression;

		this.$backendTracking.trackSraEvent(impressionAction, this.sponsoredAd);

		if (!this.hasImpressionExperiment) {
			// External tracking
			this.externalTrackingImpressionUrl();

			if (this.impressionTagInternal) this.addImpressionTrackingPixelInternal();
			if (this.impressionTagExternal) this.addImpressionTrackingPixelExternal();
		}
	}

	// Impression Experiment
	sendAdRenderedEvent() {
		const impressionString = this.hasImpressionExperiment ? 'impression' : 'ad_render';
		const impressionAction: SraEventAction = this.hasImpressionExperiment
			? SraEventAction.Impression
			: SraEventAction.AdRender;

		const properties: TrackingProviderPropertiesInterface = { action: impressionString, nonInteraction: true };
		if (this.impressionTrackingLabel) properties.label = this.impressionTrackingLabel;
		if (this.impressionTrackingProperty) properties.property = this.impressionTrackingProperty;
		if (this.impressionTrackingValue) properties.value = this.impressionTrackingValue;

		const contexts = [...this.additionalContexts];
		if (this.titleObjectId && this.titleObjectType) {
			contexts.push(new SnowplowTitleContextGraphql(this.titleObjectId, this.titleObjectType));
		}

		TrackingHelper.trackEvent('sponsored_recommendations', properties, contexts);
		this.$backendTracking.trackSraEvent(impressionAction, this.sponsoredAd);

		if (this.hasImpressionExperiment) {
			// External impression tracking
			this.externalTrackingImpressionUrl();

			if (this.impressionTagInternal) this.addImpressionTrackingPixelInternal();
			if (this.impressionTagExternal) this.addImpressionTrackingPixelExternal();
		}
	}

	async watchNowClicked() {
		this.$backendTracking.trackSraEvent(SraEventAction.WatchnowClicked, this.sponsoredAd);

		// External tracking
		this.externalTrackingClickoutUrl();
	}

	// External tracking
	get externalClickoutUrl(): ExternalTracker[] {
		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ClickoutUrl
		);
	}

	async externalTrackingClickoutUrl() {
		if (await this.hasStrictlyAllConsentsGranted()) {
			handleExternalClickoutTrackers({
				externalTrackers: this.externalClickoutUrl,
				trackerType: 'external_clickout_tracker_fired',
				bidId: this.sponsoredAd.bidId,
				webLocale: this.webLocale,
			});
		}
	}

	get externalImpressionUrl(): ExternalTracker[] {
		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ImpressionUrl
		);
	}

	get externalImpressionHtml(): ExternalTracker[] {
		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ImpressionHtml
		);
	}

	async toggleWatchlist(title: typeof this.campaignNode) {
		this.$backendTracking.trackSraEvent(SraEventAction.WatchlistClicked, this.sponsoredAd);

		const titleDetails: ListMutationTitleDetailParam = {
			title: title.content.title,
			objectType: title.objectType,
			objectId: title.objectId,
		};
		const trackingPayload = {
			source: 'sponsored-recommendations',
		};
		const updatedTitle = await this.mixin_setInWatchlist(
			title.nodeId,
			this.isInWatchlist,
			titleDetails,
			trackingPayload
		);

		if (updatedTitle) {
			const isInWatchlist = !!updatedTitle.data?.setInWatchlistV2.title.watchlistEntryV2?.createdAt;
			if (
				isInWatchlist &&
				(title.__typename === 'Show' || title.__typename === 'Season') &&
				title.content.isReleased
			) {
				ModalHelper.openModal(
					MarkAsSeenModalGraphql,
					{
						titleId: title.nodeId,
						titleName: title.content.title,
						titleObjectId: title.objectId,
						titleObjectType: title.objectType,
						titleContentType: undefined,
						posterUrl: title.content.posterUrl,
						backdrops: title.content.backdrops,
						seenEpisodeCount: this.seenEpisodeCount,
					} as MarkAsSeenModalProps,
					MarkAsSeenModalOptions,
					'',
					false,
					this.additionalContexts
				);
			}
		}
	}

	onPosterClick() {
		this.setSponsoredRecommendation(this.sponsoredAd);

		this.$backendTracking.trackSraEvent(SraEventAction.Click, this.sponsoredAd);
		this.$backendTracking.trackSraEvent(SraEventAction.Clickout, this.sponsoredAd);

		// External tracking
		this.externalTrackingClickoutUrl();

		// pass down poster-clicked event as 'click'
		const clickTitleEvent: SRClickTitleEvent<SingleTitleSR> = { title: this.campaignNode, index: 0 };
		this.$emit('click', clickTitleEvent);

		window.open(this.watchNowUctLink, '_blank', 'noopener');
	}

	onMoreInfoClick() {
		this.setSponsoredRecommendation(this.sponsoredAd);

		this.$backendTracking.trackSraEvent(SraEventAction.MoreInfoClicked, this.sponsoredAd);

		// navigate to title detail page
		const clickTitleEvent: SRClickTitleEvent<SingleTitleSR> = { title: this.campaignNode, index: 0 };
		this.$emit('navigateTitle', clickTitleEvent);
	}

	async hasStrictlyAllConsentsGranted() {
		if (hasUCConsentBanner(this.webLocale) && !hasCCPABanner()) {
			await Vue.$jw.ready?.waitFor(ReadyType.CONSENT_PARTNERS_ANSWERED);
		}

		return true;
		// return this.strictlyAllConsentsGranted;
	}

	async externalTrackingImpressionUrl() {
		if (this.externalImpressionUrl.length === 0) return;
		if (await this.hasStrictlyAllConsentsGranted()) {
			handleExternalClickoutTrackers({
				externalTrackers: this.externalImpressionUrl,
				trackerType: 'external_impression_tracker_fired',
				bidId: this.sponsoredAd.bidId,
				webLocale: this.webLocale,
			});
		}
	}

	async injectExternalImpressionHtml() {
		if (this.externalImpressionHtml.length === 0) return;
		if (await this.hasStrictlyAllConsentsGranted()) {
			injectExternalHtmlTrackers({
				externalTrackers: this.externalImpressionHtml,
				webLocale: this.webLocale,
				bidId: this.sponsoredAd.bidId,
			});
		}
	}

	addImpressionTrackingPixelInternal() {
		this.impressionPixelsInternal.push('_');
	}

	async addImpressionTrackingPixelExternal() {
		if (await this.hasStrictlyAllConsentsGranted()) {
			this.impressionPixelsExternal.push('_');
		}
	}

	// Rerender External tracking
	isContainerVisible = true;
	isPosterVisible = false;

	formattedExternalImpressionUrl() {
		return this.externalImpressionUrl.map(el =>
			formatExternalTracker({
				tracker: el.data,
				webLocale: this.webLocale,
				placement: getSrPlacementFromBidId(this.sponsoredAd.bidId),
				campaignName: this.sponsoredAd.campaign.name,
			})
		);
	}

	containerVisibilityCallback(isVisible: boolean) {
		this.isContainerVisible = isVisible;
		if (!isVisible) {
			this.isPosterVisible = false;
		}
	}
	// poster visibility callback
	visibilityCallback(isPosterVisible: boolean) {
		this.isPosterVisible = isPosterVisible;
	}

	get containerObservable(): ObservableOptions {
		return {
			callback: (isVisible: boolean) => this.containerVisibilityCallback(isVisible),
			intersection: {
				root: null,
				rootMargin: '0px 0px 0px 0px',
				threshold: 0.2,
			},
			once: false,
			active: true,
			throttle: 0,
		};
	}
	// Rerender External tracking
}
