
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import BasicButton from '@/ui-components/BasicButton.vue';

import { ClickoutUrl } from '@/helpers/clickout-helper';
import { trackClickOutGraphql } from '@/helpers/offer-helper';
import {
	SnowplowClickoutContextGraphql,
	SnowplowContext,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import { ObjectType } from '@/@types/graphql-types';

import type { Offer, Package } from '@/@types/graphql-types';
import { pluck } from '@/helpers/object-helper';
import type { PopularTitleGraphqlFragment } from '@/pages/graphql/fragments/PopularTitle.fragment';

const Constant = namespace('constant');
const Language = namespace('language');

@Component({
	name: 'WatchNowButton',
	components: { ProviderIcon, BasicButton },
})
export default class WatchNowButton extends Vue {
	private domainSessionId: string | null = null;

	@Prop({ default: () => ({}) }) watchNowOffer: PopularTitleGraphqlFragment['watchNowOffer'];
	@Prop({ required: true }) titleObjectId: number;
	@Prop({ required: true }) titleObjectType: ObjectType;
	@Prop({ default: null }) titleSeasonNumber: number;
	@Prop({ default: true }) lazy: boolean;
	@Prop({ default: 'default' }) size: 'default' | 'large' | 'slim';
	@Prop() rounded?: boolean;
	@Prop({ default: 'secondary' }) color: 'secondary' | 'primary';
	@Prop({ default: false }) inQuickActions?: boolean;
	@Prop({ default: false }) hideIcon: boolean;
	@Prop({ default: false }) overwriteTracking: boolean;
	@Prop({ default: false }) overwriteClickoutTracking: boolean;
	@Prop({ default: false }) overwriteClickTracking: boolean;
	// Useful for SRs where the offer link is already affiliated or from an ad network
	@Prop({ default: undefined }) hasUctSrParam: boolean | undefined;
	@Prop({ default: false }) isSponsoredRecommendation: boolean;
	@Prop({ default: false }) linkToTitlePage: string;
	@Prop({ default: 'REGULAR' }) sponsoredRecommendationType: string;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];
	@Prop({ default: null }) promotionalProviderLogo?: string;

	@Constant.State providers: Package[];

	@Language.Getter country: string;

	async created() {
		this.domainSessionId = await TrackingHelper.getDomainSessionId();
	}

	get providersById() {
		return pluck(this.providers, 'packageId');
	}

	get providersByShortName() {
		return pluck(this.providers, 'shortName');
	}

	get url() {
		return (
			new ClickoutUrl(this.watchNowOffer)
				.set('uct_country', this.country)
				.set('sid', this.domainSessionId)
				.set('uct_tryout', 1)
				// Hack: add `uct_sr` for The Supremes campaign
				.set('uct_sr', this.hasUctSrParam ? 'true' : 'false')
				.toString()
		);
	}

	get watchNowLabel() {
		return this.sponsoredRecommendationType === 'CINEMATIC' && this.isSponsoredRecommendation
			? this.$t('WEBAPP_BUY_YOUR_TICKETS')
			: this.$t('WEBAPP_SEENLIST_WATCHNOW');
	}

	watchNow() {
		this.$emit('watchNowClicked');

		const contexts = [
			...this.additionalContexts,
			new SnowplowTitleContextGraphql(this.titleObjectId, this.titleObjectType, this.titleSeasonNumber),
		];

		if (!this.watchNowOffer) {
			return;
		} else {
			if (this.watchNowOffer) {
				const clickoutContext = SnowplowClickoutContextGraphql.fromProviderOffer(this.watchNowOffer);
				if (!this.overwriteTracking && !this.overwriteClickoutTracking) {
					trackClickOutGraphql({
						offer: this.watchNowOffer as Offer,
						objectType: this.titleObjectType,
						objectId: this.titleObjectId,
						seasonNumber: this.titleSeasonNumber,
						additionalContexts: this.additionalContexts,
					});
					contexts.push(clickoutContext);
				}
			}

			if (this.linkToTitlePage) {
				return this.$router.push({ path: this.linkToTitlePage });
			}

			window.open(this.url, '_blank', 'noopener');
		}

		if (!this.overwriteTracking && !this.overwriteClickTracking) {
			TrackingHelper.trackEvent(
				'userinteraction',
				{
					action: 'watchnow_clicked',
					property: this.inQuickActions + '',
				},
				contexts
			);
		}
	}
}
